import {openDetailCalculator} from "./Accordion";
import { AimeosBasketStandard } from "../hooks/aimeos";

function createSpinner() {
    var spinner = $(document.createElement("div"));
    spinner.addClass("aimeos-spinner");
    $("body").append(spinner);
}

function removeSpinner() {
    $(".aimeos-spinner").remove();
}

function changeBasket() {

    $("body").on("click", ".basket-standard a.bu_change", ev => {
        createSpinner();
        $.get(ev.target.href, function(data) {
            $(".basket-standard").html(AimeosBasketStandard.updateBasket(data).html());
        }).always(function() {
            removeSpinner();
        }).done(() => {
            openDetailCalculator();
        });

        return false;
    });
}

export {changeBasket}
