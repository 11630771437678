import {hasClass} from "../../helpers/ClassHelper";

function openAccordion() {
    openDetailCalculatorOnLoad();
    openDetailCalculator();
    openAdditionalDetails();
    setTimeout(() =>openContent("description"), 200)
    $("body").on("click",".catalog-detail .header", (event) => {
        const accordionName = event.target.classList[1]
        openContent(accordionName)
        openHeader(event)
    })
}

function openContent(accordionName){
    for(let i = 0;i < document.querySelectorAll(".catalog-detail .content").length;i++) {
        const el = document.querySelectorAll(".catalog-detail .content")[i]
        el.classList.remove("opened")
        el.style.display = "none"
        if(hasClass(el, accordionName)){
            el.classList.add("opened")
            el.style.display = hasClass(el, "row") ? "flex" : "block"
        }
    }
}

function openHeader(event){
    for(let i = 0;i < document.querySelectorAll(".catalog-detail .header").length;i++) {
        const el = document.querySelectorAll(".catalog-detail .header")[i]
        el.classList.remove("opened")
    }
    if(!hasClass(event.target, "opened")){
        event.target.classList.add("opened")
    }
}

function openAdditionalDetails() {
    $("body").on("click","a[href='#calculator_details']", (e) => {
        $(".header.properties").click()
    });
}

function openDetailCalculator(){
    $("body").on("click",".calculator--icon.calc-icon", (event) => {
        if($(".calculator-saving-layer").hasClass("opened")){
            $(".calculator-saving-layer").removeClass("opened")
            event.target.classList.remove("opened")
        }else{
            $(".calculator-saving-layer").addClass("opened")
            event.target.classList.add("opened")
        }
    })

    $("body").on("click",".back",(event) => {
        $(".calculator-saving-layer").removeClass("opened")
        $(".calculator--icon.calc-icon").removeClass("opened")
    })

    $("body").on("click",".catalog-detail-basket .price-list", function(ev) {
        $(".price-item:not(.price-item:first-of-type)", this).slideToggle();
        $(".price-item:first-of-type", ev.delegateTarget).toggleClass("toggle-js");
    });
}

function openDetailCalculatorOnLoad(){
    if(window.location.href.indexOf("#calculator_details") > 0){
        setTimeout(() => {
           $("a[href='#calculator_details']")[0].click()
        }, 300)
    }
}

export {openAccordion, openDetailCalculator}
