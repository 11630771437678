export class Note{
    constructor(element, time){
        this.element = element;
        this.time = time;
    }

    triggerNote() {
        setTimeout(() => {
            this.element.classList.remove("note-open");
            this.element.classList.add("note-closed");
        }, this.time);
    }
}
