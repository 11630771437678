import "slick-carousel";

export class SlickSlider{

    constructor(el, options){
        this.el = el
        this.options = options
    }

    initializeSlider(){
        if($(this.el).children().length > 0) {
            $(this.el).slick(this.options)
        }
    }
}
