/**
 * First we will load all of this project"s JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import {initPolyfills} from "./helpers/polyfills";
initPolyfills()

import {getCookie, setCookie} from "./components/CookieHandler";
import {createInputObject, getCalculatedPrices, getDecodeData} from "./components/ProductPrices";
import {SlickSlider} from "./components/SlickSlider";
import {initMyaccountEvents} from "./components/MyAccount";
import {openAccordion} from "./themes/el/Accordion";
import {changeBasket} from "./components/Basket";
import {openInfoBox} from "./components/InfoBoxes";
import {Note} from "./components/Note";
import {createNuevPDF} from "./components/PDF";
import {hasClass} from "./components/MyAccount";
import {cookieCheck} from "./components/CookieConsent";
import {initFaq} from "./themes/el/Faq";
import DealerSearch from "./vue/DealerSearch";
import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";

require("jquery.mmenu");

jQuery(document).ready(function () {
    initFaq()
    cookieCheck()
    initHomepageSlider()
    initDetailSlider()
    openAccordion()
    if(document.getElementById("nuev-holder")){
        createNuevPDF()
    }
    let input = "#option-nuev-accept"

    $("body").on("click", ".checkout-standard-summary-option-nuev .single", function (e) {
        e.preventDefault()
        $(".nuev-container").addClass("show")
        $("body").addClass("overflow-hidden")
    })

    $("body").on("click", ".nuev-container .nuev-button-group .btn-primary", function (e) {
        e.preventDefault()
        $(".nuev-container").removeClass("show")
        $(input).attr("checked", true)
        $(input).prop("checked", true)
        $("input[name=cs_option_nuev_value]").val(1)
        $("body").removeClass("overflow-hidden")
    })

    $("body").on("click",".nuev-container .nuev-button-group .btn-primary--light", function (e) {
        e.preventDefault()
        $(".nuev-container").removeClass("show")
        $(input).attr("checked", false)
        $(input).prop("checked", false)
        $("input[name=cs_option_nuev_value]").val(0)
        $("body").removeClass("overflow-hidden")
    })

    $("body").on("click", ".pagination .limit .limit-select", function (ev) {
        let parent = ev.target.parentNode
        while(!hasClass(parent, "limit")){
            parent = parent.parentNode
        }
        $(parent).children(".limit-select-content").toggleClass("d-block")
    })

    $("body").on("click", ".pagination .sort .sort-select", function (ev) {
        let parent = ev.target.parentNode
        while(!hasClass(parent, "sort")){
            parent = parent.parentNode
        }
        $(parent).children(".sort-select-content").toggleClass("d-block")
    })

    if(document.getElementById('app')) {
        Vue.use(VueGoogleMaps, {
            load: {
                key: process.env.MIX_GOOGLE_MAPS_API_KEY,
                libraries: "places" // necessary for places input
            }
        })

        new Vue({
            el: '#app',
            components: {
                DealerSearch
            }
        })
    }
})


window.onload = () => {
    if(document.getElementById("note")){
        let note = new Note(document.getElementById("note"), 3000);
        note.triggerNote();
    }
    initMyaccountEvents()
    changeBasket()
    if(getCookie("calcdata") === null){
        setCookie()
    }
    if(typeof $(".calculate") !== "undefined") {
        $(".calculate").on("click", function (e) {
            setCookie()
        })
    }
    initializeProductsOnPage()
};

if($(window).width() < 768) {
    openInfoBox()
    jQuery(document).ready(function( $ ) {
        $(".header-right .meta-navigation").children().appendTo("#menu > ul");

        $(".header").addClass("mm-slideout");
        $("#menu").mmenu({
            "extensions" : [
                "pagedim-black"
            ]
        });
        const api = $("#menu").data("mmenu");
        api.bind("open:finish", () => {
            setSelected();
            closeMobileMenuPanel();
            openActiveList();
            document.querySelector('.mm-panel_has-navbar').classList.add("mm-panel_opened");
            document.querySelector('.mm-panel_has-navbar').classList.remove("mm-hidden");
        });
    });
}

function openActiveList(){
    $(".cat-item.selected").each((index, el) => {
        $(el).parent().parent().removeClass("mm-hidden");
        $(el).parent().parent().addClass("mm-panel_opened");
    });
}

function closeMobileMenuPanel(){
    $(".mm-panel.mm-panel_has-navbar").each((index, el) => {
        $(el).addClass("mm-hidden");
        $(el).removeClass("mm-panel_opened");
    });
}

function setSelected(){
    $(".cat-item.mm-listitem__text").each((index, el) => {
        if($(el).attr("href") === (window.location.pathname + window.location.search)) {
            $(el).parent().addClass("selected");
        }
    });
}

function initializeProductsOnPage() {
    if($(".price-list").length !== 0) {
        const formData = JSON.parse(getDecodeData())
        const inputObj = createInputObject(formData)
        getCalculatedPrices(inputObj)
    }
}

function initHomepageSlider(){
    if(document.querySelectorAll("#homepage-slider").length > 0 ||
    document.querySelectorAll("#homepage-brand-slider").length > 0 ||
    document.querySelectorAll("#product-slider").length > 0 ||
    document.querySelectorAll("#homepage-mobile-slider").length > 0){

        if(window.innerWidth > 567){
            const slider = new SlickSlider("#homepage-slider",{
                prevArrow:'<div class="a-left control-c prev slick-prev"></div>',
                nextArrow:'<div class="a-right control-c next slick-next"></div>'
            });
            slider.initializeSlider();
            document.getElementById("homepage-slider").classList.remove("initializing")
        } else {
            const slider = new SlickSlider("#homepage-slider-mobile",{
                prevArrow:'<div class="a-left control-c prev slick-prev"></div>',
                nextArrow:'<div class="a-right control-c next slick-next"></div>'
            });
            slider.initializeSlider();
            document.getElementById("homepage-slider-mobile").classList.remove("initializing")

        }

        const brandslider = new SlickSlider("#homepage-brand-slider",{
            prevArrow:'<div class="a-left control-c prev slick-prev"></div>',
            nextArrow:'<div class="a-right control-c next slick-next"></div>',
            infinite: true,
            slidesToShow: 7,
            slidesToScroll: 7,
            dots: true,
            responsive: [
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        })
        brandslider.initializeSlider();
        appendTopProductsToProductSlider();
        const productslider = new SlickSlider("#product-slider",{
            prevArrow:'<div class="a-left control-c prev slick-prev"></div>',
            nextArrow:'<div class="a-right control-c next slick-next"></div>',
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            dots: true,
            responsive: [
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        })
        productslider.initializeSlider();

        $(window).resize(function() {
            brandslider.slick("resize");
            slider.slick("resize");
        });
    }
}

function initDetailSlider() {
    if(document.querySelectorAll("#details-image-slider").length > 0 ||
        document.querySelectorAll("#similar-products-slider").length){
        const detailslider = new SlickSlider("#details-image-slider", {
            prevArrow:'<div class="a-left control-c prev slick-prev"></div>',
            nextArrow:'<div class="a-right control-c next slick-next"></div>',
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true
        })
        detailslider.initializeSlider()

        const similarProductsSlider = new SlickSlider("#similar-products-slider", {
            prevArrow:'<div class="a-left control-c prev slick-prev"></div>',
            nextArrow:'<div class="a-right control-c next slick-next"></div>',
            dots: true,
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        })
        similarProductsSlider.initializeSlider()
    }
}

function appendTopProductsToProductSlider(){
    $(".top-products .list-items").children().appendTo("#product-slider");
}


