<template>
    <div>
        <div v-if="getTabs.length > 1" class="w-100">
            <div class="container px-8">
                <ul class="tab-wrapper">
                    <li class="mr-1" v-for="item in getTabs">
                        <button
                            @click="fetchDealerByProductType(item)"
                            :class="[tabType === item ? 'active ' : '']"
                            class="tab-icon"
                        >
                            <component :is="`Icon` + item" />
                        </button>
                    </li>
                </ul>
            </div>
        </div>
        <div class="portal--store-location-wrapper">
            <div class="portal--store-location-headline">
                <div v-if="hasError" class="portal--error">
                    {{ errorMessage }}
                </div>
                <div class="portal--store-location-searchbar">
                    <input
                        class="portal--searchbar"
                        type="text"
                        placeholder="Name des Händlers, Stadt oder Postleitzahl"
                        @keyup.enter="search()"
                        v-model="request.address_string"
                    />
                    <button
                        @click="search()"
                        class="portal--searchbar-search-button"
                    >
                        <svg
                            class="icon search black"
                            viewBox="0 0 40 40"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                        >
                            <g
                                id="Icon-Font/Suche"
                                stroke="none"
                                stroke-width="1"
                                fill-rule="evenodd"
                            >
                                <path
                                    d="M25.7492687,23.3350551 L34.9142136,32.5 L33.5,33.9142136 L24.4219998,24.8362134 C22.3595463,26.7968082 19.5702834,28 16.5,28 C10.1487254,28 5,22.8512746 5,16.5 C5,10.1487254 10.1487254,5 16.5,5 C22.8512746,5 28,10.1487254 28,16.5 C28,19.0598133 27.1636375,21.4242837 25.7492687,23.3350551 Z M16.5,26 C21.7467051,26 26,21.7467051 26,16.5 C26,11.2532949 21.7467051,7 16.5,7 C11.2532949,7 7,11.2532949 7,16.5 C7,21.7467051 11.2532949,26 16.5,26 Z"
                                    id="Combined-Shape"
                                    fill-rule="nonzero"
                                ></path>
                            </g>
                        </svg>
                    </button>
                </div>
            </div>

            <div class="mt-6 portal--store-location-body-wrapper">
                <div class="portal--store-location-body">
                    <ul class="portal--store-location-stores">
                        <li
                            class="portal--store-location-store"
                            v-for="dealer of orderedUsers"
                            :data-address="dealer.address"
                            @click="getDealer($event, dealer)"
                        >
                            <div
                                :class="[
                                    dealer.hasOwnProperty('distance') &&
                                    showDistance
                                        ? 'distance'
                                        : '',
                                ]"
                                class="portal--store-location-store--left"
                            >
                                <span
                                    class="portal--store-name"
                                    @click.stop
                                    @click="getDealer($event, dealer, true)"
                                    >{{ dealer.name }}</span
                                >
                                <span v-if="dealer.zip || dealer.city"
                                    >{{ dealer.zip }} {{ dealer.city }}</span
                                >
                                <span v-if="dealer.address">{{
                                    dealer.address
                                }}</span>
                                <a
                                    v-if="dealer.telephone"
                                    :href="`tel:${dealer.telephone}`"
                                    rel="noopener noreferrer"
                                >
                                    {{ dealer.telephone }}
                                </a>
                                <a
                                    v-if="dealer.website"
                                    :href="dealer.website"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {{ dealer.website }}
                                </a>
                            </div>
                            <div
                                class="portal--store-location-store--right"
                                v-if="
                                    dealer.hasOwnProperty('distance') &&
                                    showDistance
                                "
                            >
                                <span class="portal--store-distance"
                                    >Entfernung:
                                    <span
                                        >{{
                                            parseInt(dealer.distance)
                                        }}
                                        km</span
                                    ></span
                                >
                            </div>
                        </li>
                        <li v-if="dealers.length === 0 && !isLoading">
                            <span
                                >Es wurde kein Händler in Ihrem Umkreis
                                gefunden.</span
                            >
                        </li>
                    </ul>
                </div>
                <div class="portal--store-location-map-wrapper">
                    <div
                        class="portal--store-location-map"
                        :class="{ loading: isLoading }"
                    >
                        <gmap-map
                            :center="center"
                            :zoom="zoom"
                            style="width: 100%"
                        >
                            <gmap-info-window
                                @closeclick="open = false"
                                :opened="open"
                                :options="{
                                    pixelOffset: {
                                        width: -3,
                                        height: -5,
                                    },
                                }"
                                :position="infoWindowPosition"
                            >
                                <div v-html="infoContent"></div>
                            </gmap-info-window>
                            <gmap-marker
                                :key="index"
                                v-for="(m, index) in markers"
                                :position="m.position"
                                :icon="marker"
                                :clickable="true"
                                v-if="markers.length !== 0"
                                @click="clickMarker(m.position, dealers[index])"
                            ></gmap-marker>
                        </gmap-map>
                    </div>
                    <div class="loader" :class="{ loading: isLoading }"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash";
import IconSPedelec from "./icons/IconSPedelec.vue";
import IconVelo from "./icons/IconVelo.vue";

export default {
    name: "dealer-search",
    components: { IconSPedelec, IconVelo },

    props: {
        apiUrl: String,
        tabs: {
            default: "",
            type: String,
        },
    },
    data() {
        return {
            hasError: false,
            errorMessage: "",
            showDistance: false,

            center: { lat: 51.1642292, lng: 10.4541194 },
            locationName: "",
            markers: [],
            marker: {
                url: "",
                size: { width: 40, height: 60, f: "px", b: "px" },
                scaledSize: { width: 35, height: 55, f: "px", b: "px" },
            },
            places: [],
            currentPlace: null,
            request: {
                address_string: "",
                range: 100,
                type: "list",
                country: "DE",
            },
            response: {},
            dealers: [],
            currentDealer: {},
            open: false,
            infoContent: "",
            infoWindowPosition: {
                lat: 0,
                lng: 0,
            },
            zoom: 11,
            isLoading: false,
            productType: "Velo",
            searchString: "",
        };
    },
    async created() {
        const showTabs = this.tabs.split(",");
        let type = showTabs[0];

        this.tabType = type;
        this.productType = type;
        await this.geolocate();
        this.reverseGeocoding();
    },
    mounted() {
        this.marker.url = require("../../../public/customer_data/marker.png");
    },

    computed: {
        orderedUsers() {
            return _.orderBy(this.dealers, ["distance"]);
        },
        getTabs() {
            const showTabs = ["Velo", "SPedelec"];
            const givenTabs = this.tabs.split(",");
            const filteredArray = showTabs.filter((value) =>
                givenTabs.includes(value)
            );

            return filteredArray;
        },
    },

    methods: {
        showErrorMessage(error) {
            this.hasError = true;
            this.errorMessage = error;
        },

        hideErrorMessage() {
            this.hasError = false;
            this.errorMessage = "";
        },

        enableStaticMap() {
            this.showDistance = false;
            this.zoom = 6;
            this.center = { lat: 51.1642292, lng: 10.4541194 };
        },

        disableStaticMap() {
            this.showDistance = true;
            this.zoom = 11;
        },

        handleGeolocationError(errorCode) {
            if (errorCode == 1)
                this.showErrorMessage(
                    "Dein Standort konnte leider nicht ermittelt werden. Bitte benutze die Suche unten für genaue Treffer."
                );
            else
                this.showErrorMessage(
                    "Die Standortbestimmung wurde abgelehnt. Bitte benutze die Suche unten für genaue Treffer."
                );
        },

        async getGeolocationPosition() {
            if (navigator.geolocation) {
                return new Promise((resolve, reject) =>
                    navigator.geolocation.getCurrentPosition(resolve, reject)
                );
            } else {
                return new Promise((resolve) => resolve({}));
            }
        },

        async getCurrentPosition() {
            const position = await this.getGeolocationPosition();
            return [position.coords.latitude, position.coords.longitude];
        },

        // receives a place object via the autocomplete component
        setPlace(place) {
            this.currentPlace = place;
        },

        addMarker() {
            if (this.currentPlace) {
                const marker = {
                    lat: this.currentPlace.geometry.location.lat(),
                    lng: this.currentPlace.geometry.location.lng(),
                };
                this.markers.push({ position: marker });
                this.places.push(this.currentPlace);
                this.center = marker;
                this.currentPlace = null;
            }
        },

        async geolocate() {
            try {
                const [lat, lng] = await this.getCurrentPosition();
                this.center = {
                    lat,
                    lng,
                };
                this.disableStaticMap();
            } catch (error) {
                this.handleGeolocationError(error.code);
                this.enableStaticMap();
            }
        },

        setInitialDealer() {
            this.isLoading = true;
            fetch(
                `${
                    this.apiUrl
                }?filter[address][string]=${this.locationName.toLowerCase()}&filter[range]=10000&type=${
                    this.request.type
                }&filter[address][country]=${
                    this.request.country
                }&filter[attributes][producttype]=${this.productType}`,
                {
                    method: "GET",
                    mode: "cors",
                    cache: "no-cache",
                }
            )
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.hasOwnProperty("dealers")) {
                        this.dealers = data.dealers;
                        this.setMarkers();
                    }
                    this.isLoading = false;
                });
        },

        search() {
            if (this.request.address_string !== "") {
                if (!this.isLoading) {
                    this.isLoading = true;
                    this.hideErrorMessage();
                    this.showDistance = true;
                    this.open = false;
                    fetch(
                        `${
                            this.apiUrl
                        }?filter[address][string]=${this.request.address_string.toLowerCase()}&filter[range]=${
                            this.request.range
                        }&type=${this.request.type}&filter[address][country]=${
                            this.request.country
                        }&filter[attributes][producttype]=${this.productType}`,
                        {
                            method: "GET",
                            mode: "cors",
                            cache: "no-cache",
                        }
                    )
                        .then((resp) => resp.json())
                        .then((data) => {
                            if (
                                data.hasOwnProperty("location") &&
                                data.hasOwnProperty("dealers") &&
                                data.location !== null
                            ) {
                                this.dealers = data.dealers;
                                this.center = data.location.pos;
                            } else {
                                if (data.location === null) {
                                    this.fetchDealerByName();
                                } else {
                                    this.dealers = [];
                                }
                            }
                            this.setMarkers();
                            this.isLoading = false;
                        })
                        .catch(() => {
                            this.fetchDealerByName();
                        });
                    this.zoom = 11;
                    this.currentDealer = {};
                }
            }
        },
        fetchDealerByName() {
            fetch(
                `${
                    this.apiUrl
                }?filter[name]=${this.request.address_string.toLowerCase()}&type=list&sortby=name&filter[attributes][producttype]=${
                    this.productType
                }`,
                {
                    method: "GET",
                    mode: "cors",
                    cache: "no-cache",
                }
            )
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.dealers.length > 0) {
                        this.dealers = data.dealers;
                        this.center.lat = parseFloat(data.dealers[0].latitude);
                        this.center.lng = parseFloat(data.dealers[0].longitude);
                    } else {
                        this.dealers = [];
                    }
                    this.setMarkers();
                    this.isLoading = false;
                })
                .catch(() => {
                    this.dealers = [];
                    this.isLoading = false;
                });
        },
        fetchDealerByProductType(dealerType) {
            this.isLoading = true;
            this.tabType = dealerType;
            this.productType = dealerType
            let tempLocation = this.locationName.toLowerCase();
            let tempRange = 10000;

            if (this.searchString !== "") {
                tempLocation = this.searchString;
                tempRange = 100;
            }
            fetch(
                `${this.apiUrl}?filter[address][string]=${tempLocation}&filter[range]=${tempRange}&type=${this.request.type}&filter[address][country]=${this.request.country}&filter[attributes][producttype]=${dealerType}`,
                {
                    method: "GET",
                    mode: "cors",
                    cache: "no-cache",
                }
            )
                .then((resp) => resp.json())
                .then((data) => {
                    if (data.hasOwnProperty("dealers")) {
                        this.dealers = data.dealers;
                        this.setMarkers();
                    }
                    this.isLoading = false;
                });
        },

        setMarkers() {
            this.markers = [];
            this.dealers.forEach((dealer) => {
                this.markers.push({
                    position: {
                        lat: parseFloat(dealer.latitude),
                        lng: parseFloat(dealer.longitude),
                    },
                });
            });
        },
        getDealer(event, dealer, isParent = false) {
            this.removeAllActiveStates();
            !isParent
                ? event.target.classList.add("active")
                : event.target.parentNode.classList.add("active");
            this.currentDealer = dealer;
            this.setPosition(dealer);
            this.open = true;
            this.infoWindowPosition = {
                lat: parseFloat(dealer.latitude),
                lng: parseFloat(dealer.longitude),
            };
            this.infoContent = this.infoWindowContentBuilder(dealer);
        },
        clickMarker(position, dealer) {
            this.open = true;
            this.currentDealer = dealer;
            this.infoWindowPosition = position;
            this.infoContent = this.infoWindowContentBuilder(dealer);
            this.removeAllActiveStates();
            this.setDealerActiveInList(dealer);
        },
        removeAllActiveStates() {
            if (
                document.querySelectorAll(
                    ".portal--store-location-store.active"
                ).length > 0
            ) {
                document
                    .querySelectorAll(".portal--store-location-store.active")
                    .forEach((el) => {
                        el.classList.remove("active");
                    });
            }
        },
        infoWindowContentBuilder(dealer) {
            let infoContent = "";
            infoContent +=
                dealer.name !== ""
                    ? "<span>" + dealer.name + "</span><br>"
                    : "";
            infoContent +=
                dealer.address !== ""
                    ? "<span>" + dealer.address + "</span><br>"
                    : "";
            infoContent +=
                dealer.zip !== "" ? "<span>" + dealer.zip + " </span>" : "";
            infoContent +=
                dealer.city !== ""
                    ? "<span>" + dealer.city + "</span><br>"
                    : "";
            infoContent +=
                dealer.email !== ""
                    ? "<span>" + dealer.email + "</span><br>"
                    : "";
            infoContent +=
                dealer.telephone !== ""
                    ? "<span>" + dealer.telephone + "</span><br>"
                    : "";
            infoContent +=
                dealer.website !== ""
                    ? "<span>" + dealer.website + "</span><br>"
                    : "";
            infoContent +=
                dealer.hasOwnProperty("distance") && dealer.distance !== ""
                    ? "<strong><span>" +
                      parseInt(dealer.distance) +
                      " km entfernt</span></strong><br>"
                    : "";
            return infoContent;
        },
        reverseGeocoding() {
            fetch(
                this.apiUrl +
                    "?type=geocode&filter[address][string]=" +
                    this.center.lat +
                    "," +
                    this.center.lng,
                {
                    method: "GET",
                }
            )
                .then((resp) => resp.json())
                .then((data) => {
                    this.locationName = data.address.postalcode;
                    this.setInitialDealer();
                });
        },
        setDealerActiveInList(dealer) {
            document
                .querySelectorAll(".portal--store-location-store")
                .forEach((el, index) => {
                    if (
                        document.getElementsByClassName("portal--store-name")[
                            index
                        ].innerText === dealer.name &&
                        el.dataset.address === dealer.address
                    ) {
                        el.classList.add("active");
                        this.scrollToActiveDealer();
                    }
                });
        },
        scrollToActiveDealer() {
            const body = document.querySelector(".portal--store-location-body");
            const active = document.querySelector(
                ".portal--store-location-store.active"
            );

            body.scrollTop = active.offsetTop - body.offsetTop;
        },
        setPosition(dealer) {
            this.center.lat = parseFloat(dealer.latitude);
            this.center.lng = parseFloat(dealer.longitude);
        },
    },
};
</script>
<style lang="scss">
.tab-icon {
    width: 6rem;
    background: rgb(229 231 235);
    height: 4.5rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
    padding: 0.5rem;
    border: none;
    outline: 0 none;
}

.tab-icon.active {
    background-color: rgb(255, 212, 0);
    height: 5rem;
}
.tab-wrapper {
    -webkit-text-size-adjust: 100%;
    tab-size: 4;
    line-height: inherit;
    pointer-events: all;
    border: 0 solid #e5e7eb;
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: flex-end;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}

.portal--store-location-body-wrapper {
    display: flex;
}

.portal--store-name {
    margin-bottom: 5px;
    font-weight: 700;
    font-size: 18px;
}

.portal--store-location-body {
    width: 35%;
    max-height: 550px;
    overflow-y: auto;
}

.portal--store-location-searchbar {
    display: flex;
    margin: 10px 0;
}

.portal--searchbar {
    flex: 1 1 0%;
    padding: 8px;
    height: 42px;
    border: 1px solid black;
    border-right: none;
    outline: 0 none !important;
}
.portal--store-location-wrapper {
    position: relative;
}
.portal--searchbar-search-button {
    width: 80px;
    height: 42px;
    background: #f3f3f3;

    border: 1px solid black;
    outline: 0 none !important;
}

.portal--store-location-store {
    font-size: 16px;
    cursor: pointer;
    transition: ease all 0.3s;
    tab-size: 4;
    line-height: inherit;
    pointer-events: all;
    list-style: none;
    border: 0 solid #e5e7eb;
    box-sizing: border-box;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding-bottom: 1rem;
    padding-top: 1rem;
    padding-right: 0.75rem;
}

.portal--store-location-store:hover {
    background: rgb(229, 231, 235);
}
.portal--store-location-store--right {
    width: 32%;
}
.portal--store-location-store--left {
    line-height: inherit;
    pointer-events: all;
    list-style: none;
    cursor: pointer;
    font-size: 16px;
    border: 0 solid;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    width: 100%;
}

.portal--store-location-store--left.distance {
    width: 68%;
}

.portal--store-location-stores {
    margin-right: 1rem;
    padding-left: 0;
    padding-button: 1rem;
}
.portal--store-location-store--left a {
    color: #42a5f5;
}

.portal--store-location-map-wrapper {
    width: 65%;

    background: #f3f3f3;
}

.portal--store-location-map-wrapper .vue-map-container {
    height: 550px;
}
.black {
    fill: #000;
    width: 2.5rem;
    height: 2.5rem;
    margin: auto;
}

.portal--store-location-map.loading {
    position: relative;
    z-index: 999;

    background-color: rgba(255, 255, 255, 0.5);
    filter: blur(3px);
}

.portal--store-location-map.loading * {
    pointer-events: none;
}

.portal--searchbar-search-button:hover {
    cursor: pointer;
}

.portal--error {
    background-color: white;
    padding: 10px;
    border: 1px solid black;
    margin-top: 10px;
    margin-bottom: 10px;
    color: red;
    outline: 0 none !important;
}

.loader {
    display: none;
}

.portal--store-distance {
    font-size: 14px;
}
.portal--store-distance span {
    white-space: nowrap;
}
.loader.loading {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1000;

    display: block;
    width: 75px;
    height: 75px;
    margin-top: -32px;
    margin-left: -32px;

    border: 3px solid #ddd;
    border-top: 3px solid #3b3b3b;
    border-radius: 50%;
    filter: blur(0px);

    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@media (max-width: 991px) {
    .portal--store-location-body-wrapper {
        flex-direction: column;
    }

    .portal--store-location-body {
        order: 2;
        width: 100%;
        height: 425px;
        margin-top: 1rem;
    }

    .portal--store-location-map-wrapper {
        width: 100%;
    }

    .portal--store-location-map .vue-map-container {
        height: 350px;
    }
}
</style>
