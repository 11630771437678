import {hasClass} from "../helpers/ClassHelper";

function openVueOrderReports(){
    if(document.querySelectorAll(".order-report-button")) {
        Array.from(document.querySelectorAll(".order-report-button")).forEach(button => {
            button.addEventListener("click", event => {
                Array.from(event.target.parentElement.parentElement.children).forEach(el => {
                    if(el.classList.contains("order-reports")){
                        if(el.classList.contains("opened")){
                            el.classList.remove("opened");
                        } else {
                            el.classList.add("opened");
                        }
                    }
                })
            })
        })
    }
}


function getOrderData() {
    $(".account-history .history-item").on("click", " > span", function(ev) {
        const details = $(".account-history-order", ev.delegateTarget);
        if((details.length === 0 && !hasClass(ev.target, "isloading"))) {
            $.get($(this).data("href"), function(data) {
                const doc = document.createElement("html");
                doc.innerHTML = data;
                const node = $(".account-history-order", doc);
                node.css("display", "none");
                $(ev.delegateTarget).append(node);
                node.slideDown();
            }).done((data) => {
                openOrderReports()
            });

        } else {
            details.slideToggle()
        }

        return false;
    });
}

function initMyaccountEvents(){
    getOrderData();
}

function openOrderReports(){
    let orderreports;
    $("body").on("click",".order-report-button", (event) => {
        for(let i=0;i<event.target.parentElement.parentElement.children.length;i++){
            if(hasClass(event.target.parentElement.parentElement.children[i], "order-reports")){
                orderreports = event.target.parentElement.parentElement.children[i];
            }
        }
        if(hasClass(orderreports, "opened")){
            orderreports.classList.remove("opened");
        } else {
            orderreports.classList.add("opened");
        }
    })
}


export {initMyaccountEvents, hasClass, openVueOrderReports }
