const animationClasses = [".loader", ".price-text", ".ordernow__button-text", ".costs"]

/**
 * Creates product input object
 * @returns product object
 */
function createProductObject() {

    let productObj = {}

    $(".price-list").each((index, el) => {
        const prodid = $(el).find(".articleitem").data("prodid")
        const priceHash = $(el).find(".mpp-price-item").data("price-hash")
        const main = $(el).find(".mpp-price-item").data("main")

        if (typeof prodid !== "undefined") {
            productObj[prodid] = {
                "id": prodid,
                "price": priceHash,
                "main": main
            }
        }
    })

    return productObj
}

function getDecodeData() {
    return atob($("body").data("calcdata"));
}

/**
 * Re-inserts calculated prices into price template
 * @param responseObject
 */
function insertCalculatedPrices(responseObject) {
    for (let key in responseObject) {
        if (responseObject.hasOwnProperty(key)) {
            let product = $(".price-list").find(".articleitem[data-prodid=" + key + "] .value");
            for (let childkey in responseObject[key]) {
                $(product).html(new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR"}).format(responseObject[key][childkey].leasing_net))
            }
        }
    }
}

/**
 * Creates input object
 * @returns inputObject
 */
function createInputObject(inputData) {
    return {
        "authorization": inputData.authorization,
        "salary": inputData.salary,
        "products": createProductObject()
    }
}

/**
 * Gets calculated prices from api based on input
 * @param inputObject
 */
async function getCalculatedPrices(inputObject) {
    const url = process.env.MIX_CALCULATOR_URL+'/api/calculate'

    try{
        $.ajax({
            url: url,
            type: "post",
            headers: {
                "Accept": "application/x.calculator.v1+json",
                "Cache-Control": "no-cache"
            },
            data: inputObject
        }).done((response) => {
            insertCalculatedPrices(response.products_calculated)
            animationClasses.forEach(className => removeAnimation(className))
        })
    } catch(error) {console.error(error)}
}

function removeAnimation(el) {
    $(el).removeClass("isloading");
}


export {createInputObject, getCalculatedPrices, insertCalculatedPrices, getDecodeData}

