function initFaq() {
    openFirstTabOnLoad();

    $("body").on("click", ".faq .topic", function (event) {
        closeAllTabs()
        openTab(this)
    })

    $("body").on("click", ".faq .faqs .question", function (event) {
        openAnswer(this)
    })
}

function openFirstTabOnLoad() {
    if ($(".faq .topic").not(".active")) {
        setTimeout(() => {
            openTab(".faq .topic:first-child")
        }, 100)
    }
}

function openTab(el) {
    $(el).addClass("active")
    let topic = $(el).data("topic")
    $(".faqs[data-topic='" + topic + "']").addClass("active")
}

function closeAllTabs() {
    $(".faq .topic").removeClass("active")
    $(".faqs").removeClass("active")
}

function openAnswer(el) {
    if($(el).parent().hasClass("active")) {
        $(el).parent().removeClass("active")
    } else {
        $(el).parent().addClass("active")
    }
}

export {initFaq}
