function hasClass(el, className) {
    if(typeof el !== "undefined"){
        for(let child in el.classList) {
            if(el.classList[child] === className) {
                return true
            }
        }
    }
    return false
}

function isChildren(el, children) {
    let child = false;
    for(let i = 0; i < el.children.length;i++){
        if(el.children[i].className === children){
            child = true;
            break;
        }
    }
    return child
}

export {hasClass, isChildren}
