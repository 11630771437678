import {closeElements} from "../helpers/BoxHelper";

function openInfoBox() {
    closeInfoBoxes();
    $("body").on("click",".info--icon-small", (event) => {
        closeElements(".mobile-info--text");
        $(event.target.dataset.link).addClass("opened")
        $(".body").addClass("info-opened");
    });
}

function closeInfoBoxes() {
    $("body").on("click",".info-close", () => {
        $(".mobile-info--text").each((index, el) => {
            $(el).removeClass("opened");
            $(".body").removeClass("info-opened");
        })
    })
}

export {openInfoBox}