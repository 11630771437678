export const salaryData = {
    setSalaryInputValues: (salaryObj) => {
        const inputs = Array.from(document.querySelectorAll(".form-field input"))
        const selectFields = Array.from(document.querySelectorAll(".select-value select"))
        inputs.forEach(el => {
            if(typeof el.name !== "undefined") {
                if(el.type === "checkbox") {
                    el.value = +el.checked
                }
                salaryObj[el.name] = el.value;
            }
        })

        selectFields.forEach(el => {
            if(typeof el.name !== "undefined") {
                salaryObj[el.name] = el.value;
            }
        })
    },
    setDefaultSalaryValues: () => {
        return {
            "grossmonthlysalary": 3000,
            "state": "BW",
            "childtax": 0,
            "taxclass": 1,
            "churchtax": 1,
            "pensioninsurance": 1,
            "healthinsurance": 1,
            "healthinsurancecontribution": 0.9
        }
    },
}
