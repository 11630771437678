import {hasClass} from "../helpers/ClassHelper";

function openAccordion() {
    openDetailCalculatorOnLoad();
    openDetailCalculator();
    openAdditionalDetails();

    $("body").on("click",".catalog-detail .header", (event) => {
        if(hasClass(event.target, "opened")){
            event.target.classList.remove("opened")
        } else {
            event.target.classList.add("opened")
        }
    })
}

function openAdditionalDetails() {
    $("body").on("click","a[href='#calculator_details']", (e) => {
        $("#calculator_details .header.properties").addClass("opened");
        $("#calculator_details .content.properties").css("display", "block");
        $("#calculator_details .content.properties").removeClass("toggle");
    });
}

function openDetailCalculator(){
    $("body").on("click",".calculator--icon.calc-icon", (event) => {
        if($(".calculator-saving-layer").hasClass("opened")){
            $(".calculator-saving-layer").removeClass("opened")
            event.target.classList.remove("opened")
        }else{
            $(".calculator-saving-layer").addClass("opened")
            event.target.classList.add("opened")
        }
    })

    $("body").on("click",".back",(event) => {
        $(".calculator-saving-layer").removeClass("opened")
        $(".calculator--icon.calc-icon").removeClass("opened")
    })

    $("body").on("click",".catalog-detail-basket .price-list", function(ev) {
        $(".price-item:not(.price-item:first-of-type)", this).slideToggle();
        $(".price-item:first-of-type", ev.delegateTarget).toggleClass("toggle-js");
    });
}

function openDetailCalculatorOnLoad(){
    if(window.location.href.indexOf("#calculator_details") > 0){
        setTimeout(() => {
            $("#calculator_details .header.properties").addClass("opened");
            $("#calculator_details .content.properties").removeClass("toggle");
            $("#calculator_details .content.properties").css("display", "block");
        }, 300);
    }
}

export {openAccordion, openDetailCalculator}
