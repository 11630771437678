function closeElements(el) {
    $(el).each((index, el) => {
        if($(el).hasClass("opened")){
            $(el).removeClass("opened");
        }
    })
}

function isOpened(el) {
    return !!$(el).hasClass("opened")
}

export {closeElements}