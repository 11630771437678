import {salaryData} from "./SalaryDataHandler";

function getCookie(name) {
    const pair = document.cookie.match(new RegExp(name + "=([^;]+)"));

    return !!pair ? unescape(pair[1]) : null;
}

function deleteCookie(name) {
    document.cookie = name + "=; Max-Age=0"
}

function setCookie() {
    const cookieName = "calcdata"

    if(getCookie(cookieName) !== null){
        if(getCookie(cookieName).length !== 0) {
            deleteCookie(cookieName)
        }
    }

    let calcDataObj = {}
    window.location.pathname === "/pages/calculator" ? salaryData.setSalaryInputValues(calcDataObj) : calcDataObj = salaryData.setDefaultSalaryValues()

    document.cookie = cookieName + "=" + encodeURI(JSON.stringify(calcDataObj)) + ";path=/"
}

export {getCookie, setCookie}
