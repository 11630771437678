import * as pdfjsLib from 'pdfjs-dist';

function renderPDF(PDFdata, holder) {
    let thePdf = null;
    const scale = 5;
    pdfjsLib.getDocument({data: atob(PDFdata)}).then(function(pdf) {
        thePdf = pdf;
        const viewer = document.getElementById(holder);

        for(let page = 1; page <= pdf.numPages; page++) {
            let canvas = document.createElement("canvas");
            canvas.className = "pdf-page-canvas";
            viewer.appendChild(canvas);
            renderPage(page, canvas,scale, thePdf, holder);
        }
    });
}

function renderPage(pageNumber, canvas, scale, thePdf, holder) {
    const wrapper = document.getElementById(holder);
    thePdf.getPage(pageNumber).then(function(page) {
        let viewport = page.getViewport(scale);
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        canvas.style.width = "100%";
        canvas.style.height = "100%";
        wrapper.style.width = Math.floor(viewport.width/scale) + "pt";
        page.render({canvasContext: canvas.getContext("2d"), viewport: viewport}, holder);
    })
}

function getPDFData(className, attribute) {
    const nuev = document.getElementsByClassName(className)[0];
    return nuev.dataset[attribute];
}

function createNuevPDF() {
    const nuevdata = getPDFData("nuev-content", 'nuev');
    renderPDF(nuevdata, 'nuev-holder');
}

function createInsurancePDF() {
    const insuranceData = getPDFData("insurance-content", 'insurance');
    renderPDF(insuranceData, 'insurance-holder');
}

export {createNuevPDF, createInsurancePDF}
