import { render, staticRenderFns } from "./DealerSearch.vue?vue&type=template&id=0b279f31"
import script from "./DealerSearch.vue?vue&type=script&lang=js"
export * from "./DealerSearch.vue?vue&type=script&lang=js"
import style0 from "./DealerSearch.vue?vue&type=style&index=0&id=0b279f31&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports