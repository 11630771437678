var consentEl = document.querySelector("#cookieconsent")
var button = document.querySelector("#cookieconsent__button")

/**
 *
 * @param cName
 * @param value
 * @param exdays Use 0 for end of session, null for today + 3650 days
 */
function setCookie(cName, value, exdays) {
    var exdate
    if (exdays) {
        exdate = new Date()
        exdate.setDate(exdate.getDate() + exdays)
    } else {
        // 10 Years if exdays not specified
        exdate = new Date()
        exdate.setDate(exdate.getDate() + 3650)
    }
    var cValue = escape(value) + (exdays === 0 ? "" : "; expires=" + exdate.toUTCString())
    document.cookie = cName + "=" + cValue + "; path=/"
}

function getCookie(cName) {
    let ARRcookies = document.cookie.split(";")
    for (let i = 0; i < ARRcookies.length; i++) {
        let x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="))
        let y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1)
        x = x.replace(/^\s+|\s+$/g, "")
        // eslint-disable-next-line eqeqeq
        if (x == cName) {
            return unescape(y)
        }
    }
}

function cookieCheck() {
    var consentCookie = getCookie("cookieconsent")
    if (consentCookie || !consentEl || !button) return
    button.addEventListener("click", setCookieConsent)
    consentEl.style.display = "block"
}

function setCookieConsent(e) {
    e.currentTarget.removeEventListener("click", setCookieConsent)
    consentEl.classList.add("fadeOutOpacity")
    setCookie("cookieconsent", true)
}

export {cookieCheck}
